$hmt-purple-palette: (
  50: #eeeefb,
  100: #d6d5f6,
  200: #bab9f0,
  300: #9e9dea,
  350: #9aadfa,
  400: #8a88e6,
  450: #6779ce,
  500: #7573e1,
  550: #7573e1,
  575: #6a74c7,
  600: #6d6bdd,
  625: #6f69ba,
  650: #6361da,
  700: #6260d9,
  800: #5856d5,
  900: #4543cd,
  A100: #ffffff,
  A200: #ededff,
  e6e6e6: #e6e6e6,
  beb6ff: #beb6ff,
  D3D3D3: #d3d3d3,
  d8d3ff: #d8d3ff,
  ECE7FC: #ece7fc,
  EAE7F8: #eae7f8,
  D4D4D4: #d4d4d4,
  A300: #cbd5fc,
  A400: #bbbaff,
  A700: #a2a1ff,
  9AADFA: #9aadfa,
  7472DF: #7472df,
  7166B6: #7166b6,
  7264B3: #7264b3,
  7068B9: #7068b9,
  6F69BA: #6f69ba,
  7165B5: #7165b5,
  54547C: #54547c,
  605EDD: #605edd,
  3F51B5: #3f51b5,
  765BA8: #765ba8,
  4c1796: #4c1796,
  8D85C6: #8d85c6,
  6a73c5: #6a73c5,
  747DD0: #747dd0,
  645EF6: #645ef6,
  645EF7: #645ef7,
  5C5CE6: #5c5ce6,
  6B6BFF: #6b6bff,
  000000: #000000,
  0000005C: #0000005c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    141414: #141414,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
  opacity: (
    0000004D: #0000004d,
    000000b3: #000000b3,
  ),
);

$hmt-ash-palette: (
  FFFFFF: #ffffff,
  FAFAFA: #fafafa,
  F2F2F2: #f2f2f2,
  F3F3F3: #f3f3f3,
  F4F4F4: #f4f4f4,
  F7F7F7: #f7f7f7,
  F5F4F4: #f5f4f4,
  F5F5F5: #f5f5f5,
  F1F1F1: #f1f1f1,
  F2F4F7: #f2f4f7,
  F6F6F6: #f6f6f6,
  F6F6FE: #f6f6fe,
  F0F0F0: #f0f0f0,
  f0e4e4: #f0e4e4,
  FEFEFE: #fefefe,
  EBEBEB: #ebebeb,
  E0E0E0: #e0e0e0,
  E5E5E5: #e5e5e5,
  D4D4D4: #d4d4d4,
  D3D3D3: #d3d3d3,
  DCDCDC: #dcdcdc,
  EAEAEA: #eaeaea,
  DEDEDE: #dedede,
  DED9D9: #ded9d9,
  D6D6D6: #d6d6d6,
  D9D9D9: #d9d9d9,
  DBDBDB: #dbdbdb,
  E8E8E8: #e8e8e8,
  C3C3C4: #c3c3c4,
  C4C4C4: #c4c4c4,
  C9C9C9: #c9c9c9,
  E3E3E3: #e3e3e3,
  EFEFEF: #efefef,
  C2C2C2: #c2c2c2,
  CCCCCC: #cccccc,
  C1C1C1: #c1c1c1,
  e9e5e5: #e9e5e5,
  F9F9F9: #f9f9f9,
  B8B8B8: #b8b8b8,
  BDBDBD: #bdbdbd,
  B2B2B2: #b2b2b2,
  999999: #999999,
  A9A9A9: #a9a9a9,
  A6A6A6: #a6a6a6,
  A3A3A3: #a3a3a3,
  AEAEAE: #aeaeae,
  B5B5B5: #b5b5b5,
  8C8C8C: #8c8c8c,
  969C9D: #969c9d,
  818181: #818181,
  BFBFBF: #bfbfbf,
  828282: #828282,
  808080: #808080,
  77ee7e: #7e7e7e,
  6D6D6D: #6d6d6d,
  66eE6E: #6e6e6e,
  616161: #616161,
  6C6C6C: #6c6c6c,
  5C5C5C: #5c5c5c,
  575757: #575757,
  4F4F4F: #4f4f4f,
  565656: #565656,
  464646: #464646,
  404040: #404040,
  66ee6e: #6e6e6e,
  434343: #434343,
  3F3F3F: #3f3f3f,
  001529: #001529,
  222157: #222157,
  333333: #333333,
  292929: #292929,
  242533: #242533,
  030303: #030303,
  A8A7A7: #a8a7a7,
  555: #555,
  444: #444,
  444444: #444444,
  666666: #666666,
  414141: #414141,
  595959: #595959,
  858585: #858585,
  262626: #262626,
  55eE5E: #5e5e5e,
  ddd6d6: #ddd6d6,
  F8F8F8: #f8f8f8,
  DFDFDF: #dfdfdf,
  757575: #757575,
  9488eE: #948e8e,
  FFF8F8: #fff8f8,
  99eE9E: #9e9e9e,
  CDD1F2: #cdd1f2,
  opacity: (
    EBEBEB4D: #ebebeb4d,
    AEAEAE4D: #aeaeae4d,
  ),
);

$hmt-green-palette: (
  00471F: #00471f,
  079F44: #079f44,
  64CB2C: #64cb2c,
  5cb85c: #5cb85c,
  5CB85C: #5cb85c,
  4DA25B: #4da25b,
  4EA852: #4ea852,
  35A023: #35a023,
  219653: #219653,
  27AE60: #27ae60,
  06DF9C: #06cf9c,
  6FCF97: #6fcf97,
  00A352: #00a352,
  389D: #389e0d,
  9ee271: #9ee271,
);

$hmt-blue-palette: (
  7674e226: #7674e226,
  4A44E5: #4a44e5,
  073566: #073566,
  0B51A6: #0b51a6,
  3368CA: #3368ca,
  4591FA: #4591fa,
  2F80ED: #2f80ed,
  337AB7: #337ab7,
  357DE1: #357de1,
  314c9e: #314c9e,
  6084DB: #6084db,
  2D9CDB: #2d9cdb,
  4689EF: #4689ef,
  56CCF2: #56ccf2,
  38D0D6: #38d0d6,
  ECF4FF: #ecf4ff,
  6A73C7: #6a73c7,
  765BA8: #765ba8,
  75C9ED: #75c9ed,
  3F51B5: #3f51b5,
  0050B3: #0050b3,
);

$hmt-custom-palette: (
  FF4D4F: #ff4d4f,
  FF7875: #ff7875,
  F2994A: #f2994a,
  FA541C: #fa541c,
  9A0E0E: #9a0e0e,
  8B0000: #8b0000,
  F44336: #f44336,
  F5222D: #f5222d,
  D4380D: #d4380d,
  FF0000: #ff0000,
  FD4B4B: #fd4b4b,
  E1060B: #e1060b,
  FF1313: #ff1313,
  B52D07: #b52d07,
  FF3636: #ff3636,
  DB3535: #db3535,
  DB752C: #db752c,
  E5A84C: #e5a84c,
  FD9A36: #fd9a36,
  AD6800: #ad6800,
  E6E6E6: #e6e6e6,
  EEEEEE: #eeeeee,
  EB5757: #eb5757,
  FFB4B3: #ffb4b3,
  EFC951: #efc951,
  E1B635: #e1b635,
  F2C94C: #f2c94c,
  F3E7C2: #f3e7c2,
  FFFBEF: #fffbef,
  DDDDDD: #dddddd,
  808080: #808080,
  7068B9: #7068b9,
  007AF9: #007af9,
  4A9EDD: #4a9edd,
  8e78E3: #8e78e3,
  AF1716: #af1716,
  f2b54c: #f2b54c,
  FC4646: #fc4646,
  FFA500: #ffa500,
  FFA940: #ffa940,
  52C41A: #52c41a,
  7CB305: #7cb305,
  FFC069: #ffc069,
  E27515: #e27515,
  CF1322: #cf1322,
  f2b4b4: #f2b4b4,
  D62828: #d62828,
  4A4E69: #4a4e69,
);

$hmt-dialog-opacity: 0.4;
$hmt-dialog-backdrop-color: rgba(191, 191, 191, $hmt-dialog-opacity);

//font-icons
$hm-icon-search-document: '\e932';
$hm-icon-thumbs-up: '\e933';
$hm-icon-airbus: '\e944';
$hm-icon-calender-with-clock: '\e946';
$hm-icon-car-accident: '\e947';
$hm-icon-clip: '\e948';
$hm-icon-container-on-a-crane-1: '\e949';
$hm-icon-Printer: '\e94a';
$hm-icon-container-on-a-crane: '\e94b';
$hm-icon-import-task: '\e94c';
$hm-icon-map-folded: '\e94d';
$hm-icon-right-arrow-line: '\e931';
$hm-icon-locaton-pin-point: '\e93f';
$hm-icon-access-time: '\e940';
$hm-icon-anchor-pin: '\e941';
$hm-icon-call-tone: '\e942';
$hm-icon-close-object: '\e943';
$hm-icon-history-clock: '\e945';
$hm-icon-icon-star-empty: '\e93e';
$hm-icon-certification-award: '\e92a';
$hm-icon-lorry: '\e92b';
$hm-icon-sand-clock-dashboard: '\e92c';
$hm-icon-security-officer: '\e92d';
$hm-icon-truck-dashboard: '\e92e';
$hm-icon-envelop-dashboard: '\e92f';
$hm-icon-license-card: '\e930';
$hm-icon-morgage-loan: '\e934';
$hm-icon-redar-dashboard: '\e935';
$hm-icon-rim: '\e936';
$hm-icon-settings-dashboard: '\e937';
$hm-icon-user-avatar-dashboard: '\e938';
$hm-icon-oil-can: '\e939';
$hm-icon-steeering-wheel: '\e93a';
$hm-icon-warning: '\e93b';
$hm-icon-Group: '\e93c';
$hm-icon-transport-in-out: '\e93d';
$hm-icon-export: '\e900';
$hm-icon-import: '\e901';
$hm-icon-dashboard-meter: '\e902';
$hm-icon-manager: '\e903';
$hm-icon-verfied: '\e904';
$hm-icon-two-way-arrows: '\e905';
$hm-icon-ad-blocker: '\e906';
$hm-icon-swim-lanes: '\e907';
$hm-icon-list: '\e908';
$hm-icon-forward-task: '\e909';
$hm-icon-contract: '\e90a';
$hm-icon-doc-complete: '\e90b';
$hm-icon-new-message: '\e90c';
$hm-icon-price-tag: '\e90d';
$hm-icon-sand-clock: '\e90e';
$hm-icon-check-list: '\e90f';
$hm-icon-mover-truck: '\e910';
$hm-icon-stopwatch: '\e911';
$hm-icon-anchor: '\e91f';
$hm-icon-arrow-left: '\e920';
$hm-icon-arrow-right: '\e921';
$hm-icon-calender: '\e922';
$hm-icon-check: '\e923';
$hm-icon-ellipse: '\e929';
$hm-icon-envelope: '\e912';
$hm-icon-envelope-line: '\e913';
$hm-icon-forward: '\e914';
$hm-icon-history: '\e915';
$hm-icon-inbox: '\e916';
$hm-icon-out-box: '\e917';
$hm-icon-pdf: '\e918';
$hm-icon-plus: '\e924';
$hm-icon-print: '\e925';
$hm-icon-reply: '\e926';
$hm-icon-search-line: '\e927';
$hm-icon-ship: '\e928';
$hm-icon-ship-3d: '\e919';
$hm-icon-sms: '\e91a';
$hm-icon-split: '\e91b';
$hm-icon-trash-line: '\e91c';
$hm-icon-truck: '\e91d';
$hm-icon-user-avatar: '\e91e';
