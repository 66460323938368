@use '@angular/material' as mat;
@use 'sass:map';

@import 'abstract/variables';

@include mat.core();

$hmt-primary: mat.define-palette($hmt-purple-palette);
$hmt-accent: mat.define-palette($hmt-purple-palette);
$hmt-warn: mat.define-palette(mat.$red-palette);

$hmt-theme: mat.define-light-theme(
  (
    color: (
      primary: $hmt-primary,
      accent: $hmt-accent,
      warn: $hmt-warn,
    ),
    typography: mat.define-typography-config(),
  )
);

@include mat.all-component-themes($hmt-theme);

.error {
  color: map.get($hmt-ash-palette, FFFFFF);
  background-color: map.get($hmt-custom-palette, FF1313);
}

.success {
  color: map.get($hmt-ash-palette, FFFFFF);
  background-color: map.get($hmt-green-palette, 27AE60);
}

.warning {
  color: map.get($hmt-ash-palette, FFFFFF);
  background-color: map.get($hmt-custom-palette, F2994A);
}
