@mixin box-shadow($top, $left, $blur, $size, $color) {
  -webkit-box-shadow: $top $left $blur $size $color;
  -moz-box-shadow: $top $left $blur $size $color;
  box-shadow: $top $left $blur $size $color;
}

// Mixin to handle media queries easily
$breakpoints: (
  xs: 384px,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xl2: 1336px,
  uv: 1440px,
  2xl: 1536px,
  3xl: 1720px,
  4xl: 1920px,
);

@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @if type-of($breakpoint) == number {
      @media (max-width: $breakpoint) {
        @content;
      }
    } @else {
      @warn "Unknown breakpoint #{$breakpoint}.Please either one of the following: #{map-keys($breakpoints)} or a custom value in pixels.";
    }
  }
}

@mixin mq-min($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @if type-of($breakpoint) == number {
      @media (min-width: $breakpoint) {
        @content;
      }
    } @else {
      @warn "Unknown breakpoint #{$breakpoint}. Please use one of the following: #{map-keys($breakpoints)} or a custom value in pixels.";
    }
  }
}
