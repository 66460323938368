@use '@angular/material' as mat;
@import 'abstract/mixins';
@import 'abstract/functions';

.mat-mdc-form-field,
.mat-mdc-form-field-type-mat-select,
.mat-mdc-form-field-type-mat-input {
  @include mat.all-component-densities(-2);
  font-size: px-to-rem(15px) !important;

  @include mq(uv) {
    @include mat.all-component-densities(-5);
    font-size: px-to-rem(14px) !important;
  }

  @include mq(lg) {
    font-size: px-to-rem(13px) !important;
  }

  label {
    font-size: px-to-rem(15px) !important;

    @include mq(uv) {
      font-size: px-to-rem(14px) !important;
    }

    @include mq(lg) {
      font-size: px-to-rem(13px) !important;
    }
  }
}

.mat-mdc-option,
.mat-mdc-menu-item,
.mat-mdc-list-item {
  min-height: auto !important;
  padding-block: px-to-rem(6px) !important;
  padding-inline: 1rem !important;
  font-size: px-to-rem(15px) !important;

  @include mq(uv) {
    font-size: px-to-rem(14px) !important;
  }

  @include mq(lg) {
    font-size: px-to-rem(13px) !important;
  }
}

.mat-mdc-menu-item-text,
.mdc-list-item__content,
.mdc-list-item__primary-text {
  font-size: px-to-rem(15px) !important;

  @include mq(uv) {
    font-size: px-to-rem(14px) !important;
  }

  @include mq(lg) {
    font-size: px-to-rem(13px) !important;
  }
}
