/*
 * Make sure to import this file into every SCSS file and use the provided utility classes instead of
 * directly using margin, padding, etc.
 * Avoid writing separate classes in component SCSS files.
 * This approach helps maintain consistency throughout the application and makes it easier to implement changes.
 * It also reduces the amount of CSS code in component SCSS files.
 */

@import './variables';
@import './functions';

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.bold-500 {
  font-weight: 500;
}

.bold-600 {
  font-weight: 600;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

// width and height

.width-10 {
  width: 10%;
}

.width-20 {
  width: 20%;
}

.width-30 {
  width: 30%;
}

.width-40 {
  width: 40%;
}

.width-50 {
  width: 50%;
}

.width-60 {
  width: 60%;
}

.width-70 {
  width: 70%;
}

.width-80 {
  width: 80%;
}

.width-90 {
  width: 90%;
}

.full-width {
  width: 100%;
}

.height-10 {
  height: 10%;
}

.height-20 {
  height: 20%;
}

.height-30 {
  height: 30%;
}

.height-40 {
  height: 40%;
}

.height-50 {
  height: 50%;
}

.height-60 {
  height: 60%;
}

.height-70 {
  height: 70%;
}

.height-80 {
  height: 80%;
}

.height-90 {
  height: 90%;
}

.full-height {
  height: 100%;
}

.full-width-height {
  @extend .full-height;
  @extend .full-width;
}

// flex direction columns and rows
.flex {
  display: flex;
}

.ai-c {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.fill-space {
  flex: 1;
}

// vertical align center
.va-c {
  align-items: center;
}

.ha-c {
  justify-content: center;
}

.c-p {
  cursor: pointer;
}

// NOTE: Margin values used through out the application. Use these classes instead of directly using margin property in scss.

.margin-0 {
  margin: 0;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 1px;
}

.m-2 {
  margin: 2px;
}

.m-3 {
  margin: 3px;
}

.m-4 {
  margin: 4px;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 25px;
}

.m-30 {
  margin: 30px;
}

.mt-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 1px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-3 {
  margin-top: 3px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-3 {
  margin-bottom: 3px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 1px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-3 {
  margin-left: 3px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-1 {
  margin-right: 1px;
}

.mr-2 {
  margin-right: 2px;
}

.mr-3 {
  margin-right: 3px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-100 {
  margin-right: px-to-rem(200px);
}

.mr-200 {
  margin-right: px-to-rem(200px);
}

// NOTE: Common padding values used through out the application. Use these classes instead of directly using padding property in scss.

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 1px;
}

.p-2 {
  padding: 2px;
}

.p-3 {
  padding: 3px;
}

.p-4 {
  padding: 4px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 1px;
}

.pt-2 {
  padding-top: 2px;
}

.pt-3 {
  padding-top: 3px;
}

.pt-4 {
  padding-top: 4px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 1px;
}

.pb-2 {
  padding-bottom: 2px;
}

.pb-3 {
  padding-bottom: 3px;
}

.pb-4 {
  padding-bottom: 4px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 1px;
}

.pl-2 {
  padding-left: 2px;
}

.pl-3 {
  padding-left: 3px;
}

.pl-4 {
  padding-left: 4px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 1px;
}

.pr-2 {
  padding-right: 2px;
}

.pr-3 {
  padding-right: 3px;
}

.pr-4 {
  padding-right: 4px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-80 {
  padding-right: 80px;
}

// gap values

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 1px;
}

.gap-2 {
  gap: 2px;
}

.gap-3 {
  gap: 3px;
}

.gap-4 {
  gap: 4px;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.max-width-700 {
  max-width: px-to-rem(790px);
}

.max-width-800 {
  max-width: px-to-rem(900px);
}

// scroll

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

// Button Util Style Classes

.btn-base {
  letter-spacing: normal;
}

.btn-grey-outline {
  @extend .btn-base;
  border: 1px solid map-get($map: $hmt-ash-palette, $key: #d9d9d9);
  color: #595959;
}

.btn-purple-outline {
  @extend .btn-base;
  border: 1px solid map-get($map: $hmt-ash-palette, $key: #d9d9d9);
  color: #6664d1;
}

.btn-purple-fill {
  @extend .btn-base;
  background-color: #6664d1;
  color: #ffffff;
}

.btn-green-fill {
  @extend .btn-base;
  background-color: #389e0d;
  color: #ffffff;
}

.btn-red-fill {
  background-color: #d4380d;
  color: #ffffff;
}

.btn-red-outline {
  @extend .btn-base;
  border: 1px solid map-get($map: $hmt-custom-palette, $key: #cf1322);
  color: #cf1322;
}

.btn-green-outline {
  @extend .btn-base;
  border: 1px solid #389e0d;
  color: #389e0d;
}

.disabled-button {
  @extend .btn-base;
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none;
  background-color: #e7e6e6;
  color: #9d9d9d;
  border: 1px solid #bdbdbd !important;

  &:hover {
    background-color: #e0e0e0;
    color: #bdbdbd;
  }

  mat-icon {
    color: #bdbdbd;
  }
}

// Select Util Style Classes (Override material mat-select default styles to match styles in figma ui design)

.hmt-select-base {
  ::ng-deep .mat-mdc-floating-label {
    padding-bottom: px-to-rem(15px);
  }

  ::ng-deep .mat-mdc-text-field-wrapper {
    max-height: px-to-rem(40px);
  }

  ::ng-deep .mat-mdc-form-field-infix {
    padding-top: px-to-rem(10px);
  }
}

// Chip Styles

.chip-base {
  font-size: 12px;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 16px;
  color: #ffffff;
}

.published,
.pending {
  @extend .chip-base;
  background-color: #f2994a;
}

.in-progress,
.offered,
.awarded,
.completed {
  @extend .chip-base;
  background-color: #219653;
}

.evaluating,
.common {
  @extend .chip-base;
  background-color: #0050b3;
}

.expired,
.rejected,
.terminated {
  @extend .chip-base;
  background-color: #cf1322;
}

.purple-chip {
  @extend .chip-base;
  background-color: #6664d1;
}

// Common styles for displaying country flags

.flag-icon-base {
  img {
    border-radius: 50%;
  }
}

.flag-icon-sm {
  @extend .flag-icon-base;
  height: px-to-rem(20px);
  width: px-to-rem(20px);
}

.flag-icon-md {
  @extend .flag-icon-base;
  height: px-to-rem(30px);
  width: px-to-rem(30px);
}

.flag-icon-lg {
  @extend .flag-icon-base;
  height: px-to-rem(60px);
  width: px-to-rem(60px);
}

// Border Styles

.border {
  border: px-to-rem(1px) solid map-get($hmt-ash-palette, E0E0E0);
}

.border-bottom {
  border-bottom: px-to-rem(1px) solid map-get($hmt-ash-palette, E0E0E0);
}

.border-left {
  border-left: px-to-rem(1px) solid map-get($hmt-ash-palette, E0E0E0);
}

.border-right {
  border-right: px-to-rem(1px) solid map-get($hmt-ash-palette, E0E0E0);
}

.border-top {
  border-top: px-to-rem(1px) solid map-get($hmt-ash-palette, E0E0E0);
}

// Border Radius

.border-radius-1 {
  border-radius: px-to-rem(1px);
}

.border-radius-2 {
  border-radius: px-to-rem(2px);
}

.border-radius-3 {
  border-radius: px-to-rem(3px);
}

.border-radius-4 {
  border-radius: px-to-rem(4px);
}

.border-radius-5 {
  border-radius: px-to-rem(5px);
}

.border-radius-10 {
  border-radius: px-to-rem(10px);
}
