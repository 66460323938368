@use '@angular/material' as mat;
@import 'abstract/variables';
@import 'abstract/functions';
@import 'abstract/mixins';
@import 'abstract/placeholders';
@import 'abstract/common';
@import 'base/typography';

@import 'theme/light';
@import 'theme/custom-component-styles';

html,
body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 0.875rem;
  background-color: #fff;
}

@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

.backdropBackground {
  background-color: $hmt-dialog-backdrop-color;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0 !important;
}

.mark-as-done-custom-dialog-container .mat-dialog-container {
  overflow: hidden;

  mat-dialog-content {
    max-height: 100vh;
  }
}

// Use this to reduce density of the form fields
.reduce-density {
  @include mat.all-component-densities(-2);
}

//TODO: move to theme file or appropriate file after proper material config
.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-2);
}

.dense-3 {
  @include mat.all-component-densities(-3);
}

.dense-4 {
  @include mat.all-component-densities(-4);
}

.ag-popup-editor {
  background-color: white !important;
}
